import { render, staticRenderFns } from "./MyAchievement.vue?vue&type=template&id=68962f48&scoped=true&"
import script from "./MyAchievement.vue?vue&type=script&lang=js&"
export * from "./MyAchievement.vue?vue&type=script&lang=js&"
import style0 from "./MyAchievement.vue?vue&type=style&index=0&id=68962f48&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68962f48",
  null
  
)

export default component.exports