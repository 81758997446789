<template>
    <div class="MyAchievement">
        <el-card class="box_card" v-for="(item,index) in Achievement" :key="index">
            <div class="item">
                <h2>评测成绩</h2>
                <el-row>
                    <el-col :span="6">
                        <p>考试成绩:</p>
                        <p>考试时间:</p>
                        <p>成绩:</p>
                    </el-col>
                    <el-col :span="18" class="val">
                        <p>{{item.name}}</p>
                        <p>{{item.created_at}}</p>
                        <p>{{item.score}}</p>
                    </el-col>
                </el-row>
                <div class="br"></div>
            </div>
        </el-card>
        <div class="tip" v-show="Achievement.length==0">
            <img src="../../assets/images/user/none.png" alt="">
            <p class="text-center">暂无成绩</p>
        </div>
    </div>
</template>
<script>
let _this
export default {
    data(){
        return{
            Achievement:[]
        }
    },
    created(){
        _this=this
        this.getScore()
    },
    methods:{
         getScore() {
            _this.$http
                .get(process.env.VUE_APP_URL + 'result')
                .then(data => {
                if (data.data.code == 200) {
                    _this.Achievement = data.data.data
                } else {
                    _this.$message.error(data.data.msg)
                }
                })
                .catch(err => {
                _this.$message.error('网络错误，请重试')
                console.log(err)
            })
        },
    }
}
</script>
<style lang="less" scoped>
    .MyAchievement{
        padding: 20px;
        h2{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
        }
        P{
            margin: 12px 0px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }
        .val{
            p{
                color: #666666!important;
            }
        }
        .br{
            width: 80vw;
            height: 1px;
            background: #DADADA;
            margin: 0px auto;
        }
        .tip{
            position: absolute;
            top: 30vh;
            left: 0px;
            right: 0px;
            p{
                color: #909399;
                font-size: 20px;
            }
            img{
                width: 100%;
            }
        }
    }
</style>